import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import * as serviceWorker from './serviceWorker';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import {AuthProvider} from "./Auth";


var config ={
    apiKey: "AIzaSyDdiMue4MURt1EfUjunbXR-gECEGmnzyR8",
    authDomain: "qsort-8f5fd.firebaseapp.com",
    databaseURL: "https://qsort-8f5fd.firebaseio.com",
    projectId: "qsort-8f5fd",
    storageBucket: "qsort-8f5fd.appspot.com",
    messagingSenderId: "713495313827",
    appId: "1:713495313827:web:9da0d195d64d3b78cfee16",
    measurementId: "G-EVN45RKBH0"
};
firebase.initializeApp(config);

ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
