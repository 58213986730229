import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import HeaderModal from './headerModal';
import InstructionDefault from '../instruction/instructionDefault';




class HeaderDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            instruction: false,

        };


        this.closeInstruction = this.closeInstruction.bind(this);
        this.openInstruction = this.openInstruction.bind(this);

      }
      closeInstruction(){
        console.log('lukt');
        this.setState({
            instruction: false
        })
    }

    openInstruction(){
        console.log('lukt');
        this.setState({
            instruction: true
        })
    }

    componentDidMount(){
        console.log("image url:"+this.props.instruction['logo'] )
    }

    

    render() {
        return (
            <div>
            <HeaderModal openInstruction={this.openInstruction} content={{logo:this.props.instruction['logo']}}/>

             {this.state.instruction ?    
            
            <InstructionDefault closeInstruction={this.closeInstruction} content={{title:this.props.instruction['title'], text: this.props.instruction['text']}}/>:null}
            </div>
        )}
};

export default HeaderDefault;
