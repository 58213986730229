import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ArchiveScreen from "../components/archive";
import CategoryScreenOverview from "../components/archive/categoryScreenOverview";

const CategoryOverview = (props) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Archief - Overzicht" subtitle="Q-sort" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Row>
      <Col lg="10">
          <CategoryScreenOverview urllink={props.match.params.catname} />
       
      </Col>
    </Row>
  </Container>
);

export default CategoryOverview;
