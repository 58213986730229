import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";

// Route Views
import QSortOverviewScreen from "./views/QSort";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import qsortSettingsView from "./views/qSortSettings";
import qsortStart from "./views/QSortStartScreen";
import qsortEnd from "./views/QSortEndScreen";
import qSortSorting from "./views/QSortSortingScreen";
import archiveScreen from "./components/archive";
import Archive from "./views/ArchiveScreen";
import CategoryOverview from "./views/ArchiveCategoryScreenOverview";
import SetOverview from "./views/ArchiveSetScreenOverview";
import addArchiveScreen from "./views/ArchiveAddScreen";
import LoginScreen from './views/LoginScreen';
import qSortOverview3 from "./components/blog/qSortOverview3";
import QSortOverviewScreen3 from "./views/QSort3";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/q-sort-settings" />
  },
  {
    path: "/q-sort-settings",
    layout: DefaultLayout,
    component: qsortSettingsView
  },
  {
    path: "/q-sort-start",
    layout: ModalLayout,
    component: qsortStart
  },
  {
    path: "/q-sort-sorting",
    layout: ModalLayout,
    component: qSortSorting
  },
  {
    path: "/q-sort-end",
    layout: ModalLayout,
    component: qsortEnd
  },
  {
    path: "/q-sort-2",
    layout: ModalLayout,
    component: QSortOverviewScreen
  },
  {
    path: "/q-sort-3",
    layout: ModalLayout,
    component: QSortOverviewScreen3
  },
  {
    path: "/archive",
    layout: DefaultLayout,
    component: Archive
  },
  {
    path: "/category-overview/:catname",
    layout: DefaultLayout,
    component: CategoryOverview
  },
  {
    path: "/set-overview/:catname/:setname/:categorykey/:setkey",
    layout: DefaultLayout,
    component: SetOverview
  },
  {
    path: "/add-archive",
    layout: DefaultLayout,
    component: addArchiveScreen
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/login",
    layout: ModalLayout,
    component: LoginScreen
  },
];
