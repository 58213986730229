

export default function() {
  return [
    {
      title: "Dashboard",
      to: "/q-sort-settings",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Archief",
      htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
      to: "/archive",
    }
   /* Pas in fase 2
    {
      title: "Rapporten",
      htmlBefore: '<i class="material-icons">insert_chart_outlined</i>',
      to: "/tables",
    },
  */
    
  ];
}
