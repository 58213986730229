import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link, Redirect } from 'react-router-dom';
import Firebase from 'firebase';

class addScreenCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: 'Categorienaam',
            categoryDesc: ''
        };
      }

      writeCategory(){
        Firebase.database().ref('/category').push().set({
          name: this.state.categoryName,
          catgoryDesc: this.state.categoryDesc,
      })
      }

      addQuestions(add){
        var newPostKey = Firebase.database().ref().child('/questions').push().key;
        Firebase.database().ref('/category/'+newPostKey).set({
          name: this.state.categoryName,
          catgoryDesc: this.state.categoryDesc,
          key:newPostKey
      })
        //window.location.href="/archive"
      }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row>

            <Col lg="12">
               <Row> 
                <Col sm="6">
              <h6 className="titles-setup">Categorie naam</h6>
              </Col>


              </Row>
            </Col>
            <Col lg="3">
  
                <div  className="settingsBlocksCat">
                <i class="fas fa-file-alt"></i> 
                <h5  className="image-steps-title">{this.state.categoryName}</h5>
                </div>
                
            </Col>
            <Col lg="12">
                <h6 className="titles-setup">Categorie gegevens</h6>
                <Row>
                  <Col lg="12"><input type="text" className="input-field-sets" placeholder="Naam van de categorie" value={this.state.categoryName} onChange={event => this.setState({ categoryName: event.target.value})}  /></Col>
                  <Col lg="12"><input type="textarea" className="input-field-sets" placeholder="Korte omschrijving" value={this.state.categoryDesc} onChange={event => this.setState({ categoryDesc: event.target.value})}  /></Col>
                  <Col lg="12" className="button-adding"> <Link className="link-setup-categorie" to={{pathname: `/archive`}}  onClick={() => this.addQuestions(this.state.newquestion)}>Voeg toe</Link> {/**  <Button variant="primary" className="button-add-category"  onClick={() => this.addQuestions(this.state.newquestion)}>Voeg toe</Button>*/}</Col>
              </Row>
            </Col>
             
           
          </Row>
        );
    }
};

export default addScreenCategory;
