import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';




class HeaderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoUrl:this.props.content['logo']
        };

      }

closeInstrucion(){
    this.props.closeInstruction()
}
    

    render() {
        return (
            <Row>
                <Col lg="6">
        
                </Col>
                <Col lg="4" className="question-instruction">
                    <p onClick={() =>  this.props.openInstruction()}>
                        <i class="far fa-question-circle"></i> Instructie nodig?
                    </p>
                </Col>
                <Col lg="2">
                    <img src={this.state.logoUrl} alt="Girl in a jacket" className="logo-client"/>
                </Col>
          </Row>
        )}
};

export default HeaderModal;
