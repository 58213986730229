import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import QSortSettings from "../components/qsortsettings/qSortSettings";
import QSortOverview from "../components/blog/qSortOverview";

const qsortSettingsView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen" subtitle="Q-sort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <QSortSettings />
    
  </Container>
);

export default qsortSettingsView;
