import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';




class InstructionDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title:this.props.content['title'],
            text: this.props.content['text']
        };

      }

closeInstrucion(){
    this.props.closeInstruction()
}
    

    render() {
        return (
            <Row>
            <Col lg="12" className="overlay">
                <Row>
                    <Col lg="9" className="add-appointment-block">
                    <h6 className="instruction-title">{this.state.title}</h6> <span className="close-instruction"><i class="fas fa-times" onClick={() => this.closeInstrucion()}></i></span>
                    <p>{this.state.text}</p>

                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </Col>
        </Row>
        )}
};

export default InstructionDefault;
