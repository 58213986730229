import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import HeaderDefault from '../header/headerDefault';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';




class qSortEnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: this.props.qsortdata.questions,
            key: this.props.qsortdata.key,
            logo:'',
            user: 0,
            participants: 0,
            doneQ: false,
            companyName: '',
            results: [],
            selectedSet:'',
            stelling:'',
            loading: false,
            comments: '',
            uploaded: false,
            uploading: false,
            companyText:''
        };
      }


      checkParticipants(user, participants){
        console.log("numeroo:"+parseInt(participants));

        console.log("input:"+ user +'/'+participants);
        if(user >= participants ){
            console.log("hello world");
            this.setState({
                doneQ: true
            })
        }
      }

      nextParticipant(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;
        let newUser = this.state.user +1;
      Firebase.database().ref('/'+dateStamp+'/'+this.state.key).update({
        user: newUser,
        
    }).then((data)=>{
        //success callback
        console.log('data ' , data)
    }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })
      }


      componentDidMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        let ref = Firebase.database().ref('/' +dateStamp+'/'+this.state.key);
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                companyName: state.companyName,
                companyText: state.companyText,
                user: state.user,
                participants: state.participants,
                questions: state.questions,
                results: state.results,
                stelling: state.stelling,
                logo: state.logo,
                loading: true
            })
            this.checkParticipants(state.user, state.participants);
            
          
        })

     
  
      }

      addComment(){

        this.setState({
          uploaded: true,
        })
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

    var newPostKey = Firebase.database().ref().child('/'+dateStamp+'/'+this.state.key+'/comments').push().key;  
        Firebase.database().ref('/'+dateStamp+'/'+this.state.key+'/comments/'+newPostKey).set({
                comment: this.state.comments,
                key: newPostKey,
                user: this.state.user,

      })

        this.setState({
          uploading: true
        })
  
      }
      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
          <div>
                         {this.state.loading ? 
            <HeaderDefault instruction={{title:'dit is een titel', text:'Hier kan een tekst', logo: this.state.logo}}/>
            :
            null}
            <Row>
            <Col lg="2"></Col>
            <Col lg="8" className="end-block">
            <Row>
     
                <Col lg="12" className="block-middle">
                    <img src={require('../../assets/images/list-check.png')}  className="image-check"/>
                </Col>

                <Col lg="12" className="block-middle-text">
                    <h6 className="titles-setup">Je gegevens zijn opgeslagen!</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempor fringilla velit id faucibus. Proin nec urna tempor nibh scelerisque dictum vitae in dui. Vestibulum convallis dolor sit amet ante venenatis scelerisque. Donec ornare dolor sit amet ante vulputate vehicula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. </p>
                    

                    {this.state.uploaded ? this.state.uploading ? <p className="check-positive"> <i class="fas fa-check"></i> Je opmerking is opgeslagen</p>: <Dots /> :
              <div className="comment-section">
            <label>Heb je nog opmerkingen?</label><br /> <br /> 
            <textarea placeholder="Opmerkingen" value={this.state.comments} onChange={event => this.setState({ comments: event.target.value, errorMessage:''})}/> <br /><br />
              <button onClick={() => this.addComment()} className="link-setup-comment">Opmerking opslaan</button></div>}<br /> <br /> 
                   
                   {this.state.doneQ ?<div><Link className="link-setup" to={{pathname: `/`}} >Afsluiten</Link> <br /> <br /> <Link className="add-participant" to={{pathname: `/q-sort-start`,state: {questions: this.state.questions, key: this.state.key}}} onClick={()=> this.nextParticipant()}>Extra kandidaat</Link></div>:<Link className="link-setup" to={{pathname: `/q-sort-start`,state: {questions: this.state.questions, key: this.state.key}}} onClick={()=> this.nextParticipant()}>Volgende kandidaat</Link>}
                </Col>
     
            </Row>
                

               
         
                
        
            </Col>
            <Col lg="2"></Col>
          </Row>
          </div>
        );
    }
};

export default qSortEnd;
