import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';

class archiveScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          questions: [
            { id: 0, questions: 'Vraag 1 over zaken omtrent HR', category: 'HR'},
            { id: 1, questions: 'Vraag 2 over zaken omtrent HR', category: 'HR'},
            { id: 2, questions: 'Vraag 3 over zaken omtrent HR', category: 'HR'},
        ],
        categories:[]
        };
      }

      componentDidMount(){
        let ref = Firebase.database().ref('/category');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            var newelement = {id: state.id, name: state.name, catgoryDesc: state.catgoryDesc};
            this.setState(prevState => ({
              
              categories: [...prevState.categories, newelement],
            }));
            console.log('firebase'+state.name);
            });
        })
  
      }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row>

            <Col lg="12" className="titles-row">
               <Row> 
                <Col sm="9">
              <h6 className="titles-setup">Categorieën</h6>
              </Col>
              <Col sm="3">
              {/*<Button variant="primary" className="button-setup"><i class="fas fa-plus"></i> Vraag toevoegen</Button>*/} <Link to={"/add-archive"} > <Button variant="primary" className="button-cat"><i class="fas fa-plus"></i> Categorie toevoegen</Button></Link>
              </Col>
              </Row>
            </Col>
            {
                this.state.categories.map((category, i) => (
                  <Col lg="3">
  
                  <div  className="settingsBlocks">
                  <i class="fas fa-file-alt"></i> 
                  <h5  className="image-steps-title">{category.name}</h5>
                    <Link to={"/category-overview/"+ category.name} >Bekijk</Link> 
                  </div>
                 
                </Col>
                ))
                }
             

             
            {/*
            <Col lg="12" className="archive-list">
              <h6 className="titles-setup">Omschrijving</h6>
              {
                this.state.questions.map((question, i) => (
                    <li key={i}><span className="question-list">{question.questions}</span> | <span className="category"><i class="fas fa-tag"></i> {question.category} </span> |  <span className="see-list-delete"><a onClick={() => this.deleteQuestion(i)}><i class="far fa-trash-alt"></i></a></span> <span className="see-list-edit"><a href="#"><i class="far fa-edit"></i></a></span></li>
                ))
              }
            
            </Col>
            */}
          </Row>
        );
    }
};

export default archiveScreen;
