import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';

class categoryScreenOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          name: this.props.urllink,
          nameOfSet: 'Set 1',
          categoryDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed mauris sapien. Praesent ornare diam nec neque vulputate pretium. Nam ut efficitur velit. Nulla sagittis blandit orci, non interdum justo vulputate et. Sed egestas nisi vel ex placerat suscipit a in massa. Aliquam a metus feugiat ex sollicitudin iaculis at non nunc. Praesent vel tellus ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
          questions: [],
          newquestion: '',
          categoryKey:'',
          id: 3,
          };
      }
    
      addQuestions(add){
        console.log(add);
        this.setState({
          questions:[]
        })
        var newPostKey = Firebase.database().ref().child('/questions').push().key;
        Firebase.database().ref('/category/'+this.state.categoryKey+'/sets/'+newPostKey).set({
          
          setname: add,
          id: this.state.id +1,
          categoryName: this.state.name,
          setKey: newPostKey
  
      } ).then((data)=>{
        this.setState({
          newquestion: '',
        })
      }).catch((error)=>{
          //error callback
          console.log('error ' , error)
      })
        

  }

  deleteQuestion(key){
      console.log(this.state.questions[key]);
      const questions = this.state.questions;
      questions.splice(key, 1);
      this.setState({ questions });
  }

  addSetToCategory(){
    Firebase.database().ref('/sets').push().set({
      nameOfSet: this.state.nameOfSet,

  }).then((data)=>{
    
  }).catch((error)=>{
      //error callback
      console.log('error ' , error)
  })
  }

  getSets(categoryKey){

    let sets = Firebase.database().ref('/category/'+ categoryKey + '/sets');
    sets.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        
        
        if(state.categoryName === this.props.urllink){

          console.log('firebase sets:'+ state.setname);
          var newelement = {id: state.id, setname: state.setname, categoryName: state.categoryName, setKey: state.setKey };
          this.setState(prevState => ({
            
            questions: [...prevState.questions, newelement],
          }));
      };

        });
        
    })
  }

  componentDidMount(){
    let ref = Firebase.database().ref('/category');
    ref.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        if(state.name === this.props.urllink){
        this.setState({
          name: state.name,
          categoryDescription: state.catgoryDesc,
          categoryKey: state.key,
          questions:[]
        
        })};
        this.getSets(state.key);

        });
        
    })






  }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row>

            <Col lg="12">
              <h6 className="titles-setup">Categorieën - {this.state.name}</h6>
            </Col>
      
              <Col lg="2">
  
                <div  className="settingsBlocksCat">
                    <i class="fas fa-file-alt"></i> 
                    <h5  className="image-steps-title">{this.state.name}</h5>
                  </div>
              </Col>
             
            <Col lg="8">
                <p>{this.state.categoryDescription} </p>
            </Col>

            <Col lg="12" className="archive-list">
              <h6 className="titles-setup">Set toevoegen</h6>

              <Row>
                  <Col lg="10"><input type="text" className="input-field-sets" placeholder="Een nieuwe categorie toevoegen aan de set.." value={this.state.newquestion} onChange={event => this.setState({ newquestion: event.target.value})}  /></Col>
                  <Col lg="2">  <Button variant="primary" className="button-add"  onClick={() => this.addQuestions(this.state.newquestion)}>Voeg toe</Button></Col>
              </Row>

            </Col>
             
            
            <Col lg="12" className="archive-list">
              <h6 className="titles-setup">Bestaande sets</h6>
              {
                this.state.questions.map((question, i) => (
                  <li key={i}><span className="question-list">{question.setname}</span> <span className="see-list"><Link to={"/set-overview/"+ this.state.name + '/'+ question.setname + '/'+this.state.categoryKey+'/'+ question.setKey} >Bekijk</Link> </span> </li>

                ))
              }
              
              
            </Col>
           
          </Row>
        );
    }
};

export default categoryScreenOverview;
