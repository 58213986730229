import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import Firebase from 'firebase';
import {AuthContext} from "../../Auth";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import LoginImage from '../../assets/images/login-image.png';

const Login = ({history}) =>{

    const handleLogin = useCallback(
         event =>{
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                 Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
                history.push("/");
            }catch (error){
                alert(error);
            }
        },
        [history]
    );

    const { currentUser} = useContext(AuthContext);

    if (currentUser){
        return <Redirect to="/" />;
    }
    
    return (
      <Row >
      <Col lg="2"></Col>
      <Col lg="4" className="login-block-left">
      <img src={require('../../assets/images/logo-ffort.png')}  className="logo-ffort"/>
          <h3>Welkom bij</h3>
          <h4>Q-sort</h4>
          <p>Een applicatie voor Q-sorting. Krijg een realistisch beeld van uw organisatie op basis van doelgerichte keuzes.</p>
          <form onSubmit={handleLogin}>
          <Row >

          <input style={{padding: 10, marginTop: 10,}} name="email" type="email" placeholder="Gebruikersnaam" />
        
          </Row>
          <Row >

              <input style={{padding: 10, marginTop: 10,}} name="password" type="password" placeholder="Wachtwoord" />
  
          </Row>
          <button style={{padding: 10,paddingLeft: 30,paddingRight: 30, marginTop: 10, backgroundColor: '#e94e1b', borderStyle: 'none', color: 'white', borderRadius:50 }} type="submit">Login</button>
      </form>
      </Col>
      <Col lg="4" className="login-block-right" >
             {/**  <img src={require('../../assets/images/logo-ffort.png')}  className="logo-ffort"/>*/}
      </Col>
      <Col lg="2"></Col>
    </Row>
    );
};


export default withRouter(Login);