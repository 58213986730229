import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import HeaderDefault from '../header/headerDefault';
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});

const grid = 8;


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

class qSortSorting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agrees:[],
            neutrals: [],
            disagrees:[],
            questions:this.props.qsortdata.questions,
            questionsbackup:this.props.qsortdata.questions,
            key: this.props.qsortdata.key,
            logo: '',
            currentnumber:0,
            showQuestions: true,
            stelling:'',
            loading: false,
            amount:0
        };
      }

      resetCards(){
          this.setState({
              currentnumber: 0,
              questions: this.state.questionsbackup,
              showQuestions: true,
              agrees: [],
              neutrals: [],
              disagrees:[]
          })
      }

      setDisagree(questionId){
         

        var newelement = questionId;
        this.setState(prevState => ({
            disagrees: [...prevState.disagrees, newelement],
            currentnumber: this.state.currentnumber +1,
          }));
          console.log(this.state.currentnumber);
          if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }

        console.log("output:"+this.state.disagrees);
      }

      setNeutral(questionId){

        var newelement = questionId;
        this.setState(prevState => ({
            neutrals: [...prevState.neutrals, newelement],
            currentnumber: this.state.currentnumber +1,
          }));
          console.log(this.state.currentnumber);
          if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }
      }

      setAgree(questionId){
       
        var newelement = questionId;
        this.setState(prevState => ({
            agrees: [...prevState.agrees, newelement],
            currentnumber: this.state.currentnumber +1,
          }));

        if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }
      }

      componentDidMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        let ref = Firebase.database().ref('/' +dateStamp+'/'+this.state.key);
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                logo: state.logo,
                stelling: state.stelling,
                loading: true,
                amount: state.amount
            })
            
            
          
        })
  
      }

      id2List = {
        droppable: 'disagrees',
        droppable2: 'neutrals',
        droppable3: 'agrees',
    };

      getList = id => this.state[this.id2List[id]];

      onDragEnd = result => {
        const { source, destination } = result;

                // dropped outside the list
                if (!destination) {
                    return;
                }else {
                    const result = move(
                        this.getList(source.droppableId),
                        this.getList(destination.droppableId),
                        source,
                        destination
                    );

                    if(source.droppableId === 'droppable' && destination.droppableId === 'droppable2' ){
                        this.setState({
                            disagrees: result.droppable,
                            neutrals: result.droppable2,
                            errorMessage:''
                    
                        });
                        }
                    else if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable' ){
                        this.setState({
                            disagrees: result.droppable,
                            neutrals: result.droppable2,
                            errorMessage:''
                    
                        });
                        }
                    
                        else if(source.droppableId === 'droppable' && destination.droppableId === 'droppable3' ){
                            this.setState({
                                disagrees: result.droppable,
                                agrees: result.droppable3,
                                errorMessage:''
                        
                            });
                            }
                            else if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable' ){
                                this.setState({
                                    disagrees: result.droppable,
                                    agrees: result.droppable3,
                                    errorMessage:''
                            
                                });
                                }
                                else if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable3' ){
                                    this.setState({
                                        neutrals: result.droppable2,
                                        agrees: result.droppable3,
                                        errorMessage:''
                                
                                    });
                                    }
                                    else if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable2' ){
                                        this.setState({
                                            neutrals: result.droppable2,
                                            agrees: result.droppable3,
                                            errorMessage:''
                                    
                                        });
                                        }

                }

      }


      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <div>
                         {this.state.loading ? 
            <HeaderDefault instruction={{title:'dit is een titel', text:'Hier kan een tekst', logo: this.state.logo}}/>
            :
            null}
            <Row>                    
              
                        <Col lg="1"></Col>
                        <Col lg="9" className="question-block">
                            {this.state.showQuestions ? 
                            <Row>

                                <Col lg="12" className="block-middle">
                                    <h5>Vraag {this.state.currentnumber}/ {this.state.questions.length}</h5>
                                </Col>
                                <Col lg="12" className="block-middle-stelling">
                                    <h5>{this.state.stelling}</h5>
                                </Col>
                                <Col lg="12" className="block-middle-text-question">
                                    <p>{this.state.questions[this.state.currentnumber].vraag}</p>
                                </Col>
                                <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setDisagree(this.state.questions[this.state.currentnumber])} ><i class="fas fa-chevron-down"></i> Mee oneens</Button>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setNeutral(this.state.questions[this.state.currentnumber])}><i class="fas fa-arrows-alt"></i> Neutraal</Button>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setAgree(this.state.questions[this.state.currentnumber])}><i class="fas fa-chevron-up"></i> Mee eens</Button>
                                        </Col>
                                </Row>
                                :
                                <Row>
                                <Col lg="12" className="block-middle">
                                    <h5>Klaar!</h5>
                                </Col>
                                <Col lg="12" className="block-middle-text">
                                    <p>Je hebt alle vragen verdeeld in 3 categorieën!</p>
                                    <Link className="link-setup" to={{pathname: `/q-sort-`+this.state.amount,state: {disagrees: this.state.disagrees, neutrals:this.state.neutrals, agrees: this.state.agrees, questions:this.state.questionsbackup, key: this.state.key, logo:this.state.logo}}} >Ga verder</Link>
                        
                                </Col>

                                </Row>
                            }
                         
                            </Col>
                            <Col lg="3" className="col-sm-1 padding-added"></Col>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                <Row className="col-sm-11 blocks">
                   
             
                    <Col lg="3" md="3" sm="3" className="mb-1 class-group-disagree">
                        <h4>Mee oneens</h4>
                        <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.disagrees.map((item, index) => (
                                <Draggable
                               
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                    </Col>
                    <Col lg="3" md="3" sm="3" className="mb-1 class-group-natural">
                        <h4>Neutraal</h4>
                        <Droppable droppableId="droppable2" >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.neutrals.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                    </Col>
                    <Col lg="3" md="3" sm="3" className="mb-1 class-group-agree">
                        <h4>Mee eens</h4>
                        <Droppable droppableId="droppable3" >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.agrees.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                    </Col>
                </Row>
                </DragDropContext>

                            {/*
                        
                         
                            <Col lg="2"></Col>

                            <Col lg="3" className="sorting-block disagrees-table">
                                <h5>Mee oneens</h5>
                                {
                                    this.state.disagrees.map((disagree, i) => (
                                        <li key={i}>{disagree.vraag} </li>
                                ))
                                }

                            </Col>
                    
                            <Col lg="3" className="sorting-block naturals-table">
                                <h5>Neutraal</h5>
                                {
                                    this.state.neutrals.map((neutral, i) => (
                                        <li key={i}>{neutral.vraag}</li>
                                ))}
                            </Col>
                          
                            <Col lg="3"className="sorting-block agrees-table">
                                <h5>Mee eens</h5>
                                {
                                    this.state.agrees.map((agree, i) => (
                                        <li key={i}>{agree.vraag }</li>
                                ))}
                            </Col>
                                    */}

                            <Col sm="4"></Col>
                            {/** 
          <Col sm="4" className="qsortdone marginbottom">
      <Button variant="primary" className="button-restart " onClick={()=> this.resetCards()}>Kaartjes herstellen</Button>
     
      </Col>*/}

          </Row>
 </div>

          
        );
    }
};

export default qSortSorting;
