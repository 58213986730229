import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

class setScreenOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.urllink,
            nameOfSet: this.props.setname,
            setKey:this.props.setkey,
            categoryKey: this.props.categorykey,
            categoryDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed mauris sapien. Praesent ornare diam nec neque vulputate pretium. Nam ut efficitur velit. Nulla sagittis blandit orci, non interdum justo vulputate et. Sed egestas nisi vel ex placerat suscipit a in massa. Aliquam a metus feugiat ex sollicitudin iaculis at non nunc. Praesent vel tellus ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
            questions: [],
            newquestion: '',
            id: 3,
            sets:[]
        };
      }



      addQuestions(add){    
        var newPostKey = Firebase.database().ref().child('/category').push().key;  
              Firebase.database().ref('/category/'+this.state.categoryKey+'/sets/'+this.state.setKey+'/questions/'+newPostKey).set({
                setname: this.props.setname,
                id: 'item-'+String(this.state.questions.length +1),
                categoryName: this.state.name,
                vraag: add,
                key: newPostKey,
                isDeleted: 0
            }).then((data)=>{
              this.setState({
                newquestion: '',
              })
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
              
      
      }


      deleteQuestion(key,sortingkey){
          console.log(key);
        const questions = this.state.questions;
          questions.splice(sortingkey, 1);
          this.setState({ questions });

          Firebase.database().ref('/category/'+ this.state.categoryKey + '/sets/'+this.state.setKey+'/questions/'+key).update({
            isDeleted:1
            
        }).then((data)=>{
            //success callback
            console.log('data ' , data)
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
      }
     
      getSets(categoryKey){
        let questions = Firebase.database().ref('/category/'+ this.state.categoryKey + '/sets/'+this.state.setKey+'/questions/');
        questions.on('value' , snapshot => {
          snapshot.forEach((childSnap) => {
          let state = childSnap.val();
          console.log("firebase output:"+state);
          if(state.setname === this.props.setname){
           
            var newelement = {id: state.id, setname: state.setname, categoryName: state.categoryName, vraag: state.vraag, key: state.key, isDeleted: state.isDeleted};
            this.setState(prevState => ({
              
              questions: [...prevState.questions, newelement],
            }));
        };
  
          });
            
        })
      }

      componentWillMount(){
        let ref = Firebase.database().ref('/category');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            if(state.name === this.props.urllink){
            this.setState({
              name: state.name,
              categoryDescription: state.catgoryDesc,
              categoryKey: state.key,
  
            
            })};
            this.setState({
              questions: []
            })
            this.getSets(state.key);
    
            })});


        
    

    
      }
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);

        return (
            <Row>

            <Col lg="12">
 
        <h6 className="titles-setup">Categorieën -  {this.props.urllink} - {this.state.nameOfSet}</h6>
            </Col>
      
              <Col lg="2">
  
                <div  className="settingsBlocksCat">
                    <i class="fas fa-file-alt"></i> 
                    <h5  className="image-steps-title">{this.state.name}</h5>
                  </div>
              </Col>
             
            <Col lg="8">
                <p>{this.state.categoryDescription} </p>
            </Col>

            <Col lg="12" className="archive-list">
              <h6 className="titles-setup">Vraag toevoegen</h6>
              <Row>
                  <Col lg="10"><input type="text" className="input-field-sets" placeholder="Een nieuwe vraag toevoegen aan de set.." value={this.state.newquestion} onChange={event => this.setState({ newquestion: event.target.value})}  /></Col>
                  <Col lg="2">  <Button variant="primary" className="button-add"  onClick={() => this.addQuestions(this.state.newquestion)}>Voeg toe</Button></Col>
              </Row>
            </Col>
             
            
            <Col lg="12" className="archive-list">
              <h6 className="titles-setup">Omschrijving</h6>
              {
                this.state.questions.filter( x => !x.isDeleted).map((question, i) => (
                    <li key={i}><span className="question-list">{question.vraag}</span>  <span className="see-list-delete"><a onClick={() => this.deleteQuestion(question.key,i)}><i class="far fa-trash-alt"></i></a></span> {/*<span className="see-list-edit"><a href="#"><i class="far fa-edit"></i></a></span>*/}</li>
                ))
              }
              
            </Col>
           
          </Row>
        );
    }
};

export default setScreenOverview;
