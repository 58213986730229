import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import HeaderDefault from '../header/headerDefault';




class qSortStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: this.props.qsortdata.questions,
            key: this.props.qsortdata.key,
            user: 0,
            participants: 0,
            logo: '',
            loading: false,
            companyText:''
     

        };

      }




      componentWillMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        let ref = Firebase.database().ref('/' +dateStamp+'/'+this.state.key);
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                user: state.user,
                participants: state.participants,
                logo: state.logo,
                companyText: state.companyText,
                loading: true
            })
        })
      }
      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
        <div>
            {this.state.loading ? 
            <HeaderDefault instruction={{title:'dit is een titel', text:'Hier kan een tekst', logo: this.state.logo}}/>
            :
            null}

           <Row>
            <Col lg="2"></Col>
            <Col lg="8" className="start-block">
            <Row>
     
                <Col lg="12" className="block-middle">
                    <img src={require('../../assets/images/list-check.png')}  className="image-check"/>
                </Col>

                <Col lg="12" className="block-middle-text">
                    <h6 className="titles-setup">Klaar om te starten!</h6>
                    <p>{this.state.companyText}</p>
                    <Link className="link-setup" to={{pathname: `/q-sort-sorting`,state: {questions: this.state.questions, key: this.state.key, logo: this.state.url}}} >Beginnen</Link>
                </Col>
     
            </Row>
                

               
         
                
        
            </Col>
            <Col lg="2"></Col>
          </Row>
  
        </div>
        );
    }
};

export default qSortStart;
